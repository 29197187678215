.tulu-component-places {
    width: 100%;
    height: 100%;
    display: flex;

    .p-side-menu {
        width: 40%;
        height: 100vh;
        max-width: 40rem;
        min-width: 25rem;

        .search-box {
            display: flex;
            justify-content: center;
	        align-items: center;
            border-bottom: 1px solid #c7c7c7;
            padding: 2.19rem 2.14rem;
        
            .search-input {
                border: 0;
                width: 100%;
                height: 20px;
                color: grey;
                font-weight: 300;
                font-size: 1.2rem;
                line-height: 20px;
                padding-left: 2rem;
                font-family: 'Open Sans', sans-serif;
                background-size: auto 1.2rem !important;
                background: url('../../../../public/images/filter_search_icon.svg') #FFFFFF no-repeat left 5px center;
        
                &:focus {
                    background-color: #FFF !important;
                    box-shadow: none;
                }
                &:hover {
                    color: #313131;

                    &::placeholder {
                        color: #313131 !important;
                    }
                }
                &::placeholder {
                    color: grey;
                }
            } 
        }

        &.outerdiv {
            height: 100vh;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #c7c7c7;
        
            .fu {
                border-bottom: 1px dotted grey;
                margin: 0;
                padding: 20px;
            }

            .innerdiv {
                flex: 1;
                overflow: auto;
            
                &::-webkit-scrollbar-track {
                    padding: 2px 0;
                    border-radius: 10px;
                    background-color: #d5d5d5;
                }
                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #9f9f9f;
                }

                .items-list {
    
                    .item {
                        padding: 0;
                        height: 8rem;
                        display: flex;
                        border-bottom: 0;
                        padding: 1.5rem 2.14rem;
            
                        .thumb-image {
                            width: 5rem;
                            height: 5rem;
                            margin-right: 1.1rem;
                            border-radius: 4px;
                            object-fit: cover;
                            padding: 0;
                            border: 0;
                        }
            
                        .details {
                            display: flex;
                            flex-direction: column;
                            width: 72%;
                            justify-content: center;
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -webkit-box-pack: center;
            
                            .title {
                                margin-top: 0px;
                                font-size: 1.2rem;
                                font-weight: 600;
                                font-family: 'Open Sans', sans-serif;
                                color: #333 !important;
                            }
                            .title:hover {
                                color: #4972e7 !important;
                            }
                            .address {
                                color: grey;
                                font-size: 1rem;
                                line-height: 20px;
                                font-family: 'Open Sans', sans-serif;
                                margin-top: 0.5rem;
                            }
                        }

                        .avatar {
                            overflow: hidden;
                            margin-right: 1.1rem;
                            border-radius: 50%;
                            width: 5rem;
                            height: 5rem;
                            object-fit: cover;
                            font-size: 2rem;
                            font-weight: 300;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.pink {
                                background-color: #ffefef;
                            }
                            &.yellow {
                                background-color: #fffdef;
                            }
                            &.green {
                                background-color: #efffef;
                            }
                            &.blue {
                                background-color: #effffe;
                            }
                            &.purple {
                                background-color: #f6efff;
                            }
                        }
                
                        &:hover {
                            cursor: pointer;
                            background-color: #fafafa;
                        }
                    }
                }
            }
        }

        .location-list_search-wrap {
            display: flex;
            align-items: center;

            color: grey;
            font-size: 1.4em;
            font-weight: 300;
            background-color: #fff;
            padding: 2.19rem 2.14rem;
            transition: color 100ms ease;
            border-bottom: 1px solid #c7c7c7;

            .custom-icon {
                width: 1.2rem;
                margin-right: 0.6rem;
            }

            >input {
                padding: 0;
                border: 0;
                color: grey;
                font-weight: 300;
                font-size: 1.2rem;
                line-height: 20px;

                &:focus {
                    background-color: #FFF !important;
                    box-shadow: none;
                }
            }

            &:hover {
                color: #313131;

                >input {
                    &::placeholder {
                        color: #313131 !important;
                    }
                }
            }
        }
    }

    .no-result {
        padding-top: 10vh;
        text-align: center;
        width: 100%;
        font-family: 'Open Sans', sans-serif;
        color: #333;
        font-size: 14px;
        line-height: 20px;
    }

    .map-side {
        width: 100%;
        background-color: #d6d6d6;
        height: 100vh;
    }
}

.padding-reset {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page-header-wrap {
    &.custom-back {
        ol {
            width: fit-content;
            cursor: pointer;
            display: flex;
        }

        .back-icon {
            font-size: 1.5rem;
            width: 1.5rem;
            margin-right: 0.5rem;
            cursor: pointer;
            color: #9b9b9c;
            text-decoration: none !important;
            font-weight: 400 !important;
            margin-left: 0rem !important;
        }
    }
}

.page-loading-wrap {
    padding-right: 2.65rem;
    padding-left: 2.65rem;
    max-width: 100%;
    overflow-x: hidden;
}

.tulu-details {

    .tulu-page-header {
        display: flex;

        .image-box {
            flex: 0 0 0%;

            .page-image {
                width: 10rem;
                height: 10rem;
                min-height: 10rem;
                min-width: 10rem;
                margin-right: 2.6rem;
                border-radius: 4px;

                &.radius-circle {
                    border-radius: 50%;
                }
            }
        }

        .details-infos {
            display: flex;
            flex-direction: column;
    
            .header-title {
                margin-bottom: 1.3rem;
                font-size: 1.8rem;
                font-weight: 600;
            }

            .dt-info {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .info-no-wrap {
                    display: flex;
	                align-items: center;
                    white-space: nowrap;
                    margin-right: 1.82rem;
                    color: #313131;
                    font-size: 1.2rem;
                    margin-bottom: 1.3rem;

                    svg {
                        margin-right: 0.5rem;
                    }

                    &:last-child {
                        margin-right: 0 !important;
                    }

                    .phone {
                        text-decoration: none;
                        color: #313131 !important;
                    }

                    .marker-icon, .buidling-icon {
                        height: 0.9rem;
                        margin-right: 0.31em;
                    }

                    .tech-blue {
                        margin-right: 0.31em;
                        -webkit-transition: opacity 100ms ease;
                        transition: opacity 100ms ease;
                        color: #4972e7;
                        text-decoration: none;
                        white-space: nowrap;
                    }

                    .people-item {
                        cursor: pointer;
                        transition: opacity 100ms ease;
                        color: #4972e7;
                        text-decoration: none;
                        white-space: nowrap;
                    }
                    .people-item:hover {
                        color: #0042ff;
                    }
                }
            }
            
        }
    }

    .no-image {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 160px;
        height: 160px;
        border-radius: 50%;
        color: #333333;
        margin-right: 1rem;
        font-size: 2rem;
        font-weight: 300;


        &.pink {
            background-color: #ffefef;
        }
        &.yellow {
            background-color: #fffdef;
        }
        &.green {
            background-color: #efffef;
        }
        &.blue {
            background-color: #effffe;
        }
        &.purple {
            background-color: #f6efff;
        }
    }

    .map-size {
        width: 100%;
        height: 300px !important;
    }
}

.padding-special {
    padding-left: 2.9rem;
    padding-right: 2.9rem;
}



@media screen and (min-width:767px) {
    .hide-on-desktop {
        display: none !important;
    }
}

@media screen and (max-width:767px) {
    .hide-on-mobile {
        display: none !important;
    }
    .tulu-places {
        .outerdiv {
            height: 95vh !important;
        }

        .items-list {
            .item {
                padding: 1rem;
            }
        }
    }

    .tulu-component-places {
        .p-side-menu {
            width: 100%;
            min-width: unset;
            max-width: unset;
            height: 93vh;
        }
    }

    .tulu-details {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .tulu-page-header {
            display: block !important;

            .image-box {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;

                .page-image {
                    margin-right: 0;
                }
            }

            .details-infos {
                .tulu-text-center {
                    text-align: center;
                }
                .dt-info {   
                    .info-no-wrap {
                        width: 100%;
                        font-size: 1rem;
                        white-space: unset !important;
                    }
                }
            }
        }

        .map-size {
            width: 100%;
            height: 200px !important;
        }
    }
}