.dark-mode {
  background-color: #1b1a1a;
}

[class^="w-icon-"],
[class*=" w-icon-"] {
  font-family: 'webflow-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.w-icon-slider-right:before {
  content: "\e600"
}

.w-icon-slider-left:before {
  content: "\e601"
}

.w-icon-nav-menu:before {
  content: "\e602"
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
  content: "\e603"
}

.w-icon-file-upload-remove:before {
  content: "\e900"
}

.w-icon-file-upload-icon:before {
  content: "\e903"
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

html {
  height: 100%
}

body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block
}

html.w-mod-touch * {
  background-attachment: scroll !important
}

.w-block {
  display: block
}

.w-inline-block {
  max-width: 100%;
  display: inline-block
}

.w-clearfix:before,
.w-clearfix:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-clearfix:after {
  clear: both
}

.w-hidden {
  display: none
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0
}

input.w-button {
  -webkit-appearance: button
}

html[data-w-dynpage] [data-w-cloak] {
  color: transparent !important
}

.w-webflow-badge,
.w-webflow-badge * {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: auto;
  display: block;
  visibility: visible;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  float: none;
  clear: none;
  border: 0 none transparent;
  border-radius: 0;
  background: none;
  background-image: none;
  background-position: 0 0;
  background-size: auto auto;
  background-repeat: repeat;
  background-origin: padding-box;
  background-clip: border-box;
  background-attachment: scroll;
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  transform: none;
  transition: none;
  direction: ltr;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
  font-variant: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  text-decoration: inherit;
  text-indent: 0;
  text-transform: inherit;
  list-style-type: disc;
  text-shadow: none;
  font-smoothing: auto;
  vertical-align: baseline;
  cursor: inherit;
  white-space: inherit;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal
}

.w-webflow-badge {
  position: fixed !important;
  display: inline-block !important;
  visibility: visible !important;
  z-index: 2147483647 !important;
  top: auto !important;
  right: 12px !important;
  bottom: 12px !important;
  left: auto !important;
  color: #AAADB0 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 6px 8px 6px 6px !important;
  font-size: 12px !important;
  opacity: 1 !important;
  line-height: 14px !important;
  text-decoration: none !important;
  transform: none !important;
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: nowrap;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer
}

.w-webflow-badge>img {
  display: inline-block !important;
  visibility: visible !important;
  opacity: 1 !important;
  vertical-align: middle !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px
}

h4 {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;
  margin-top: 0.625rem;
  color: #e9e9e9 !important;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px
}

h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px
}

p {
  margin-top: 0;
  margin-bottom: 10px
}

blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #E2E2E2;
  font-size: 18px;
  line-height: 22px
}

figure {
  margin: 0;
  margin-bottom: 10px
}

figcaption {
  margin-top: 5px;
  text-align: center
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px
}

.w-list-unstyled {
  padding-left: 0;
  list-style: none
}

.w-embed:before,
.w-embed:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-embed:after {
  clear: both
}

.w-video {
  width: 100%;
  position: relative;
  padding: 0
}

.w-video iframe,
.w-video object,
.w-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0
}

button,
html input[type='button'],
input[type='reset'] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button
}

.w-form {
  margin: 0 0 15px
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd
}

.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  font-size: 1rem !important;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999
}

.w-input:focus,
.w-select:focus {
  border-color: #3898EC;
  outline: 0
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
  background-color: #eeeeee
}

textarea.w-input,
textarea.w-select {
  height: auto
}

.w-select {
  background-color: #f3f3f3
}

.w-select[multiple] {
  height: auto
}

.w-form-label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0
}

.w-radio {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px
}

.w-radio:before,
.w-radio:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-radio:after {
  clear: both
}

.w-radio-input {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
  float: left;
  margin-left: -20px
}

.w-radio-input {
  margin-top: 3px
}

.w-file-upload {
  display: block;
  margin-bottom: 10px
}

.w-file-upload-input {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -100
}

.w-file-upload-default,
.w-file-upload-uploading,
.w-file-upload-success {
  display: inline-block;
  color: #333333
}

.w-file-upload-error {
  display: block;
  margin-top: 10px
}

.w-file-upload-default.w-hidden,
.w-file-upload-uploading.w-hidden,
.w-file-upload-error.w-hidden,
.w-file-upload-success.w-hidden {
  display: none
}

.w-file-upload-uploading-btn {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  margin: 0;
  padding: 8px 12px;
  border: 1px solid #cccccc;
  background-color: #fafafa
}

.w-file-upload-file {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0;
  padding: 8px 9px 8px 11px;
  border: 1px solid #cccccc;
  background-color: #fafafa
}

.w-file-upload-file-name {
  font-size: 14px;
  font-weight: normal;
  display: block
}

.w-file-remove-link {
  margin-top: 3px;
  margin-left: 10px;
  width: auto;
  height: auto;
  padding: 3px;
  display: block;
  cursor: pointer
}

.w-icon-file-upload-remove {
  margin: auto;
  font-size: 10px
}

.w-file-upload-error-msg {
  display: inline-block;
  color: #ea384c;
  padding: 2px 0
}

.w-file-upload-info {
  display: inline-block;
  line-height: 38px;
  padding: 0 12px
}

.w-file-upload-label {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  margin: 0;
  padding: 8px 12px;
  border: 1px solid #cccccc;
  background-color: #fafafa
}

.w-icon-file-upload-icon,
.w-icon-file-upload-uploading {
  display: inline-block;
  margin-right: 8px;
  width: 20px
}

.w-icon-file-upload-uploading {
  height: 20px
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px
}

.w-container:before,
.w-container:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-container:after {
  clear: both
}

.w-container .w-row {
  margin-left: -10px;
  margin-right: -10px
}

.w-row:before,
.w-row:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-row:after {
  clear: both
}

.w-row .w-row {
  margin-left: 0;
  margin-right: 0
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px
}

.w-col .w-col {
  padding-left: 0;
  padding-right: 0
}

.w-col-1 {
  width: 8.33333333%
}

.w-col-2 {
  width: 16.66666667%
}

.w-col-3 {
  width: 25%
}

.w-col-4 {
  width: 33.33333333%
}

.w-col-5 {
  width: 41.66666667%
}

.w-col-6 {
  width: 50%
}

.w-col-7 {
  width: 58.33333333%
}

.w-col-8 {
  width: 66.66666667%
}

.w-col-9 {
  width: 75%
}

.w-col-10 {
  width: 83.33333333%
}

.w-col-11 {
  width: 91.66666667%
}

.w-col-12 {
  width: 100%
}

.w-hidden-main {
  display: none !important
}

// video controller 
.controller li {
  position: relative;
  float: left;
  border: 15px solid #404040;
  color: #404040;
  height: 0;
  width: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin: 0 5px;
}

.controller li a {
  border-style: solid;
  text-indent: -9999px;
  position: absolute;
  top: -8px;
  left: -5px;
}

.playBtn a {
  border-color: transparent transparent transparent #fff;
  border-width: 8px 0 8px 12px;
  width: 0;
  height: 0;
}

.pauseBtn a {
  border-color: transparent white;
  border-width: 0 3px;
  height: 15px;
  width: 6px;
  left: -6px;
}

.stopBtn a {
  border: 7px solid #fff;
  height: 0;
  width: 0;
  left: -7px;
  top: -7px;
}

.forwardBtn a {
  border-left-width: 8px;
  left: 1px;
}

.forwardBtn a:first-child {
  margin-left: -7px;
}

.rewindBtn a {
  border-width: 8px 8px 8px 0;
  border-color: transparent #fff transparent transparent;
  width: 0;
  height: 0;
}

.rewindBtn a:first-child {
  margin-left: -7px;
}

.ejectBtn a.arrow {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
  top: -26px;
  left: -8px;
}

.ejectBtn a.dash {
  border-width: 0 0 4px;
  border-color: transparent transparent #fff;
  height: 0;
  width: 16px;
  left: -8px;
  top: 4px;
}

.bottom-container {
  margin-top: 600px;
}

.controller {
  background-color: black;
  /*background-image: linear-gradient(
        to right, red 50px, yellow, blue, green);*/
  /*background-position: 100% 50%;*/
  width: 100%;
  min-height: 50px;
  position: absolute;
  /*float: bottom;*/
  z-index: 99999;
  left: 0;
  bottom: 0;
}

/*.controller:hover {
	background-color: white;
}*/

.operate-container {
  min-height: 20px;
  padding: 3px;
  /*position: relative;
    top: 50%;
    transform: translateY(-50%);*/
}

.muteBtn {
  color: white;
}

.progressVoice {
  float: left;
  width: 50%;
  margin-top: 0.2rem;
  /*border: 10px solid rgba(255,255,255,0);*/
  color: #d9d9d9;
  height: 10px;

  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
}

.progressVoice::-moz-progress-bar {
  background-color: #d9d9d9;
}

.progressVoice::-webkit-progress-value {
  background-color: #d9d9d9;
}

#coverLayer {
  width: 100%;
  height: 100%;
  padding-top: 300px;
  z-index: 10000;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

#coverLayerBtn {
  width: 20%;
  height: 200px;
  border-radius: 50px;
}


.ptsLabel {
  font-size: 15px;
  color: white;
  background: rgb(0, 0, 0);
  /*border: 1px solid white;*/
  float: left;
  /*border-radius: 7px;*/
  padding: 1px;
  margin-top: 4px;
  margin-left: 5px;
}

.voice-div {
  color: white;
  /*background: rgb(0, 0, 0);
    border: 2px solid white;
    border-radius: 7px;
    padding-left: 20px;*/
  width: 18%;
  float: left;
  margin-top: 3px;
  margin-left: 10px;

  /*position: relative;*/
  /*margin-top: 50%;*/
  /*transform: translateY(-50%);*/
}

.voice-div>span {
  /*font-size: 15px;*/
  float: left;
  color: white;
  margin-right: 5px;
}

.voice-div>progress {
  margin-top: 8px;
}

.fullScreenBtn {
  /*font-size: 10px;*/
  float: right;
  margin-top: 3px;
  margin-right: 5px;
  color: white;
  /*background: #ffffff;*/
  /*border: 2px solid white;*/
  /*text-align: center;*/
  /*line-height: 20px;*/
  height: 24px;
  /*font-weight: bold;*/
  /*border-radius: 7px;*/
}

.showLabel {
  height: 18px;
  font-size: 8px;
  color: white;
  background: rgb(0, 0, 0);
  border-bottom: 1px solid #666666;
  /*border-radius: 7px;*/
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

// video controller 
.controller li {
  position: relative;
  float: left;
  border: 15px solid #404040;
  color: #404040;
  height: 0;
  width: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin: 0 5px;
}

.controller li a {
  border-style: solid;
  text-indent: -9999px;
  position: absolute;
  top: -8px;
  left: -5px;
}

.playBtn a {
  border-color: transparent transparent transparent #fff;
  border-width: 8px 0 8px 12px;
  width: 0;
  height: 0;
}

.pauseBtn a {
  border-color: transparent white;
  border-width: 0 3px;
  height: 15px;
  width: 6px;
  left: -6px;
}

.stopBtn a {
  border: 7px solid #fff;
  height: 0;
  width: 0;
  left: -7px;
  top: -7px;
}

.forwardBtn a {
  border-left-width: 8px;
  left: 1px;
}

.forwardBtn a:first-child {
  margin-left: -7px;
}

.rewindBtn a {
  border-width: 8px 8px 8px 0;
  border-color: transparent #fff transparent transparent;
  width: 0;
  height: 0;
}

.rewindBtn a:first-child {
  margin-left: -7px;
}

.ejectBtn a.arrow {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
  top: -26px;
  left: -8px;
}

.ejectBtn a.dash {
  border-width: 0 0 4px;
  border-color: transparent transparent #fff;
  height: 0;
  width: 16px;
  left: -8px;
  top: 4px;
}

.bottom-container {
  margin-top: 600px;
}

.controller {
  background-color: black;
  /*background-image: linear-gradient(
        to right, red 50px, yellow, blue, green);*/
  /*background-position: 100% 50%;*/
  width: 100%;
  min-height: 50px;
  position: absolute;
  /*float: bottom;*/
  z-index: 99999;
  left: 0;
  bottom: 0;
}

/*.controller:hover {
	background-color: white;
}*/

.operate-container {
  min-height: 20px;
  padding: 3px;
  /*position: relative;
    top: 50%;
    transform: translateY(-50%);*/
}

.progress-common {
  height: 10px;
  /** same as progress-contaniner **/
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 0px;
  border: none;
  margin-bottom: 2px;
}

.progress-contaniner {
  z-index: 1000;
  width: 100%;
  background-color: #666666;
}

.cachePts {
  position: absolute;
  z-index: 1001;
  width: 0px;
  /*margin-top: 0.125rem;*/
  background-color: #d9d9d9;
}

.muteBtn {
  color: white;
}

.progressVoice {
  float: left;
  width: 50%;
  margin-top: 0.2rem;
  /*border: 10px solid rgba(255,255,255,0);*/
  color: #d9d9d9;
  height: 10px;

  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: none;
}

.progressVoice::-moz-progress-bar {
  background-color: #d9d9d9;
}

.progressVoice::-webkit-progress-value {
  background-color: #d9d9d9;
}

#coverLayer {
  width: 100%;
  height: 100%;
  padding-top: 300px;
  z-index: 10000;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

#coverLayerBtn {
  width: 20%;
  height: 200px;
  border-radius: 50px;
}


.ptsLabel {
  font-size: 15px;
  color: white;
  background: rgb(0, 0, 0);
  /*border: 1px solid white;*/
  float: left;
  /*border-radius: 7px;*/
  padding: 1px;
  margin-top: 4px;
  margin-left: 5px;
}

.voice-div {
  color: white;
  /*background: rgb(0, 0, 0);
    border: 2px solid white;
    border-radius: 7px;
    padding-left: 20px;*/
  width: 18%;
  float: left;
  margin-top: 3px;
  margin-left: 10px;

  /*position: relative;*/
  /*margin-top: 50%;*/
  /*transform: translateY(-50%);*/
}

.voice-div>span {
  /*font-size: 15px;*/
  float: left;
  color: white;
  margin-right: 5px;
}

.voice-div>progress {
  margin-top: 8px;
}

.fullScreenBtn {
  /*font-size: 10px;*/
  float: right;
  margin-top: 3px;
  margin-right: 5px;
  color: white;
  /*background: #ffffff;*/
  /*border: 2px solid white;*/
  /*text-align: center;*/
  /*line-height: 20px;*/
  height: 24px;
  /*font-weight: bold;*/
  /*border-radius: 7px;*/
}

.showLabel {
  height: 18px;
  font-size: 8px;
  color: white;
  background: rgb(0, 0, 0);
  border-bottom: 1px solid #666666;
  /*border-radius: 7px;*/
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

.progress-common {
  height: 10px;
  /** same as progress-contaniner **/
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 0px;
  border: none;
  margin-bottom: 2px;
}

.progress-contaniner {
  z-index: 1000;
  width: 100%;
  background-color: #666666;
}

.cachePts {
  position: absolute;
  z-index: 1001;
  width: 0px;
  /*margin-top: 0.125rem;*/
  background-color: #d9d9d9;
}

/*.cachePts::-moz-progress-bar {
	background-color: #e9e9e9;
}
.cachePts::-webkit-progress-value {
	background-color: #e9e9e9;
}*/

.progressPts {
  position: absolute;
  z-index: 1002;
  width: 0px;
  /*margin-top: 0.125rem;*/
  background-color: #6950FC;
}

.location-list_name {
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  color: #e9e9e9 !important;
}
.location-list_name:hover {
  color: #82a0f3 !important;
}

/*.progressPts::-moz-progress-bar {
	background-color: yellow;
}
.progressPts::-webkit-progress-value {
	background-color: yellow;
}*/
//video controller
@media screen and (max-width:991px) {
  .w-container {
    max-width: 728px
  }

  .w-hidden-main {
    display: inherit !important
  }

  .w-hidden-medium {
    display: none !important
  }

  .w-col-medium-1 {
    width: 8.33333333%
  }

  .w-col-medium-2 {
    width: 16.66666667%
  }

  .w-col-medium-3 {
    width: 25%
  }

  .w-col-medium-4 {
    width: 33.33333333%
  }

  .w-col-medium-5 {
    width: 41.66666667%
  }

  .w-col-medium-6 {
    width: 50%
  }

  .w-col-medium-7 {
    width: 58.33333333%
  }

  .w-col-medium-8 {
    width: 66.66666667%
  }

  .w-col-medium-9 {
    width: 75%
  }

  .w-col-medium-10 {
    width: 83.33333333%
  }

  .w-col-medium-11 {
    width: 91.66666667%
  }

  .w-col-medium-12 {
    width: 100%
  }

  .w-col-stack {
    width: 100%;
    left: auto;
    right: auto
  }
}

@media screen and (max-width:767px) {
  .w-hidden-main {
    display: inherit !important
  }

  .w-hidden-medium {
    display: inherit !important
  }

  .w-hidden-small {
    display: none !important
  }

  .w-row,
  .w-container .w-row {
    margin-left: 0;
    margin-right: 0
  }

  .w-col {
    width: 100%;
    left: auto;
    right: auto
  }

  .w-col-small-1 {
    width: 8.33333333%
  }

  .w-col-small-2 {
    width: 16.66666667%
  }

  .w-col-small-3 {
    width: 25%
  }

  .w-col-small-4 {
    width: 33.33333333%
  }

  .w-col-small-5 {
    width: 41.66666667%
  }

  .w-col-small-6 {
    width: 50%
  }

  .w-col-small-7 {
    width: 58.33333333%
  }

  .w-col-small-8 {
    width: 66.66666667%
  }

  .w-col-small-9 {
    width: 75%
  }

  .w-col-small-10 {
    width: 83.33333333%
  }

  .w-col-small-11 {
    width: 91.66666667%
  }

  .w-col-small-12 {
    width: 100%
  }
}

@media screen and (max-width:479px) {
  .w-container {
    max-width: none
  }

  .w-hidden-main {
    display: inherit !important
  }

  .w-hidden-medium {
    display: inherit !important
  }

  .w-hidden-small {
    display: inherit !important
  }

  .w-hidden-tiny {
    display: none !important
  }

  .w-col {
    width: 100%
  }

  .w-col-tiny-1 {
    width: 8.33333333%
  }

  .w-col-tiny-2 {
    width: 16.66666667%
  }

  .w-col-tiny-3 {
    width: 25%
  }

  .w-col-tiny-4 {
    width: 33.33333333%
  }

  .w-col-tiny-5 {
    width: 41.66666667%
  }

  .w-col-tiny-6 {
    width: 50%
  }

  .w-col-tiny-7 {
    width: 58.33333333%
  }

  .w-col-tiny-8 {
    width: 66.66666667%
  }

  .w-col-tiny-9 {
    width: 75%
  }

  .w-col-tiny-10 {
    width: 83.33333333%
  }

  .w-col-tiny-11 {
    width: 91.66666667%
  }

  .w-col-tiny-12 {
    width: 100%
  }
}

.w-widget {
  position: relative
}

.w-widget-map {
  width: 100%;
  height: 400px
}

.w-widget-map label {
  width: auto;
  display: inline
}

.w-widget-map img {
  max-width: inherit
}

.w-widget-map .gm-style-iw {
  text-align: center
}

.w-widget-map .gm-style-iw>button {
  display: none !important
}

.w-widget-twitter {
  overflow: hidden
}

.w-widget-twitter-count-shim {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 28px;
  height: 20px;
  text-align: center;
  background: white;
  border: #758696 solid 1px;
  border-radius: 3px
}

.w-widget-twitter-count-shim * {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
  position: relative;
  font-size: 15px;
  line-height: 12px;
  text-align: center;
  color: #999;
  font-family: serif
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
  position: relative;
  display: block
}

.w-widget-twitter-count-shim.w--large {
  width: 36px;
  height: 28px
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
  font-size: 18px;
  line-height: 18px
}

.w-widget-twitter-count-shim:not(.w--vertical) {
  margin-left: 5px;
  margin-right: 8px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
  margin-left: 6px
}

.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
  top: 50%;
  left: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
  border-color: rgba(117, 134, 150, 0);
  border-right-color: #5d6c7b;
  border-width: 4px;
  margin-left: -9px;
  margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
  border-width: 5px;
  margin-left: -10px;
  margin-top: -5px
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: white;
  border-width: 4px;
  margin-left: -8px;
  margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
  border-width: 5px;
  margin-left: -9px;
  margin-top: -5px
}

.w-widget-twitter-count-shim.w--vertical {
  width: 61px;
  height: 33px;
  margin-bottom: 8px
}

.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.w-widget-twitter-count-shim.w--vertical:before {
  border-color: rgba(117, 134, 150, 0);
  border-top-color: #5d6c7b;
  border-width: 5px;
  margin-left: -5px
}

.w-widget-twitter-count-shim.w--vertical:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 4px;
  margin-left: -4px
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
  font-size: 18px;
  line-height: 22px
}

.w-widget-twitter-count-shim.w--vertical.w--large {
  width: 76px
}

.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white
}

.w-background-video>video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100
}

.w-background-video>video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none
}

.w-slider {
  position: relative;
  height: 300px;
  text-align: center;
  background: #dddddd;
  clear: both;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-mask {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  left: 0;
  right: 0;
  height: 100%;
  white-space: nowrap
}

.w-slide {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left
}

.w-slider-nav {
  position: absolute;
  z-index: 2;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding-top: 10px;
  height: 40px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-nav.w-round>div {
  border-radius: 100%
}

.w-slider-nav.w-num>div {
  width: auto;
  height: auto;
  padding: .2em .5em;
  font-size: inherit;
  line-height: inherit
}

.w-slider-nav.w-shadow>div {
  box-shadow: 0 0 3px rgba(51, 51, 51, 0.4)
}

.w-slider-nav-invert {
  color: #fff
}

.w-slider-nav-invert>div {
  background-color: rgba(34, 34, 34, 0.4)
}

.w-slider-nav-invert>div.w-active {
  background-color: #222
}

.w-slider-dot {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  margin: 0 3px .5em;
  transition: background-color 100ms, color 100ms
}

.w-slider-dot.w-active {
  background-color: #fff
}

.w-slider-dot:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fff
}

.w-slider-dot:focus.w-active {
  box-shadow: none
}

.w-slider-arrow-left,
.w-slider-arrow-right {
  position: absolute;
  width: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: pointer;
  overflow: hidden;
  color: white;
  font-size: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.w-slider-arrow-left [class^='w-icon-'],
.w-slider-arrow-right [class^='w-icon-'],
.w-slider-arrow-left [class*=' w-icon-'],
.w-slider-arrow-right [class*=' w-icon-'] {
  position: absolute
}

.w-slider-arrow-left:focus,
.w-slider-arrow-right:focus {
  outline: 0
}

.w-slider-arrow-left {
  z-index: 3;
  right: auto
}

.w-slider-arrow-right {
  z-index: 4;
  left: auto
}

.w-icon-slider-left,
.w-icon-slider-right {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 1em;
  height: 1em
}

.w-slider-aria-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.w-slider-force-show {
  display: block !important
}

.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap
}

.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px
}

.w-dropdown-toggle:focus {
  outline: 0
}

.w-icon-dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-right: 20px;
  width: 1em;
  height: 1em
}

.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%
}

.w-dropdown-list.w--open {
  display: block
}

.w-dropdown-link {
  padding: 10px 20px;
  display: block;
  color: #222222
}

.w-dropdown-link.w--current {
  color: #0082f3
}

.w-dropdown-link:focus {
  outline: 0
}

@media screen and (max-width:767px) {
  .w-nav-brand {
    padding-left: 10px
  }
}

.w-lightbox-backdrop {
  color: #000;
  cursor: auto;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  list-style: disc;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Ubuntu, "Segoe UI", Verdana, sans-serif;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  outline: 0;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate(0, 0)
}

.w-lightbox-backdrop,
.w-lightbox-container {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.w-lightbox-content {
  position: relative;
  height: 100vh;
  overflow: hidden
}

.w-lightbox-view {
  position: absolute;
  width: 100vw;
  height: 100vh;
  opacity: 0
}

.w-lightbox-view:before {
  content: "";
  height: 100vh
}

.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
  height: 86vh
}

.w-lightbox-frame,
.w-lightbox-view:before {
  display: inline-block;
  vertical-align: middle
}

.w-lightbox-figure {
  position: relative;
  margin: 0
}

.w-lightbox-group .w-lightbox-figure {
  cursor: pointer
}

.w-lightbox-img {
  width: auto;
  height: auto;
  max-width: none
}

.w-lightbox-image {
  display: block;
  float: none;
  max-width: 100vw;
  max-height: 100vh
}

.w-lightbox-group .w-lightbox-image {
  max-height: 86vh
}

.w-lightbox-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: .5em 1em;
  background: rgba(0, 0, 0, 0.4);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.w-lightbox-embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.w-lightbox-control {
  position: absolute;
  top: 0;
  width: 4em;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s
}

.w-lightbox-left {
  display: none;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==")
}

.w-lightbox-right {
  display: none;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+")
}

.w-lightbox-close {
  right: 0;
  height: 2.6em;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
  background-size: 18px
}

.w-lightbox-strip {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1vh;
  line-height: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden
}

.w-lightbox-item {
  display: inline-block;
  width: 10vh;
  padding: 2vh 1vh;
  box-sizing: content-box;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0)
}

.w-lightbox-active {
  opacity: .3
}

.w-lightbox-thumbnail {
  position: relative;
  height: 10vh;
  background: #222;
  overflow: hidden
}

.w-lightbox-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0
}

.w-lightbox-thumbnail .w-lightbox-tall {
  top: 50%;
  width: 100%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%)
}

.w-lightbox-thumbnail .w-lightbox-wide {
  left: 50%;
  height: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.w-lightbox-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border: 5px solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  -webkit-animation: spin .8s infinite linear;
  animation: spin .8s infinite linear
}

.w-lightbox-spinner:after {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 3px solid transparent;
  border-bottom-color: #fff;
  border-radius: 50%
}

.w-lightbox-hide {
  display: none
}

.w-lightbox-noscroll {
  overflow: hidden
}

@media (min-width:768px) {
  .w-lightbox-content {
    height: 96vh;
    margin-top: 2vh
  }

  .w-lightbox-view,
  .w-lightbox-view:before {
    height: 96vh
  }

  .w-lightbox-group,
  .w-lightbox-group .w-lightbox-view,
  .w-lightbox-group .w-lightbox-view:before {
    height: 84vh
  }

  .w-lightbox-image {
    max-width: 96vw;
    max-height: 96vh
  }

  .w-lightbox-group .w-lightbox-image {
    max-width: 82.3vw;
    max-height: 84vh
  }

  .w-lightbox-left,
  .w-lightbox-right {
    display: block;
    opacity: .5
  }

  .w-lightbox-close {
    opacity: .8
  }

  .w-lightbox-control:hover {
    opacity: 1
  }
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
  opacity: 0
}

.w-richtext:before,
.w-richtext:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-richtext:after {
  clear: both
}

.w-richtext[contenteditable="true"]:before,
.w-richtext[contenteditable="true"]:after {
  white-space: initial
}

.w-richtext ol,
.w-richtext ul {
  overflow: hidden
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:after {
  outline: 2px solid #2895f7
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type="image"] div {
  outline: 2px solid #2895f7
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type="video"]>div:after {
  content: '';
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0
}

.w-richtext figure {
  position: relative;
  max-width: 60%
}

.w-richtext figure>div:before {
  cursor: default !important
}

.w-richtext figure img {
  width: 100%
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
  opacity: .6
}

.w-richtext figure div {
  font-size: 0;
  color: transparent
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type="image"] {
  display: table
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type="image"]>div {
  display: inline-block
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type="image"]>figcaption {
  display: table-caption;
  caption-side: bottom
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type="video"] {
  width: 60%;
  height: 0
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type="video"] iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type="video"]>div {
  width: 100%
}

.w-richtext figure.w-richtext-align-center {
  margin-right: auto;
  margin-left: auto;
  clear: both
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type="image"]>div {
  max-width: 100%
}

.w-richtext figure.w-richtext-align-normal {
  clear: both
}

.w-richtext figure.w-richtext-align-fullwidth {
  width: 100%;
  max-width: 100%;
  text-align: center;
  clear: both;
  display: block;
  margin-right: auto;
  margin-left: auto
}

.w-richtext figure.w-richtext-align-fullwidth>div {
  display: inline-block;
  padding-bottom: inherit
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
  display: block
}

.w-richtext figure.w-richtext-align-floatleft {
  float: left;
  margin-right: 15px;
  clear: none
}

.w-richtext figure.w-richtext-align-floatright {
  float: right;
  margin-left: 15px;
  clear: none
}

.w-nav {
  position: relative;
  background: #dddddd;
  z-index: 1000
}

.w-nav:before,
.w-nav:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-nav:after {
  clear: both
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333
}

.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto
}

.w-nav-link.w--current {
  color: #0082f3
}

.w-nav-menu {
  position: relative;
  float: right
}

[data-nav-menu-open] {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #C8C8C8;
  text-align: center;
  overflow: visible;
  min-width: 200px
}

.w--nav-link-open {
  display: block;
  position: relative
}

.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%
}

.w-nav-overlay [data-nav-menu-open] {
  top: 0
}

.w-nav[data-animation="over-left"] .w-nav-overlay {
  width: auto
}

.w-nav[data-animation="over-left"] .w-nav-overlay,
.w-nav[data-animation="over-left"] [data-nav-menu-open] {
  right: auto;
  z-index: 1;
  top: 0
}

.w-nav[data-animation="over-right"] .w-nav-overlay {
  width: auto
}

.w-nav[data-animation="over-right"] .w-nav-overlay,
.w-nav[data-animation="over-right"] [data-nav-menu-open] {
  left: auto;
  z-index: 1;
  top: 0
}

.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.w-nav-button:focus {
  outline: 0
}

.w-nav-button.w--open {
  background-color: #C8C8C8;
  color: white
}

.w-nav[data-collapse="all"] .w-nav-menu {
  display: none
}

.w-nav[data-collapse="all"] .w-nav-button {
  display: block
}

.w--nav-dropdown-open {
  display: block
}

.w--nav-dropdown-toggle-open {
  display: block
}

.w--nav-dropdown-list-open {
  position: static
}

@media screen and (max-width:991px) {
  .w-nav[data-collapse="medium"] .w-nav-menu {
    display: none
  }

  .w-nav[data-collapse="medium"] .w-nav-button {
    display: block
  }
}

@media screen and (max-width:767px) {
  .w-nav[data-collapse="small"] .w-nav-menu {
    display: none
  }

  .w-nav[data-collapse="small"] .w-nav-button {
    display: block
  }

  .w-nav-brand {
    padding-left: 10px
  }
}

@media screen and (max-width:479px) {
  .w-nav[data-collapse="tiny"] .w-nav-menu {
    display: none
  }

  .w-nav[data-collapse="tiny"] .w-nav-button {
    display: block
  }
}

.w-tabs {
  position: relative
}

.w-tabs:before,
.w-tabs:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2
}

.w-tabs:after {
  clear: both
}

.w-tab-menu {
  position: relative
}

.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd
}

.w-tab-link.w--current {
  background-color: #C8C8C8
}

.w-tab-link:focus {
  outline: 0
}

.w-tab-content {
  position: relative;
  display: block;
  overflow: hidden
}

.w-tab-pane {
  position: relative;
  display: none
}

.w--tab-active {
  display: block
}

@media screen and (max-width:479px) {
  .w-tab-link {
    display: block
  }
}

.w-ix-emptyfix:after {
  content: ""
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.w-dyn-empty {
  padding: 10px;
  background-color: #dddddd
}

.w-dyn-hide {
  display: none !important
}

.w-dyn-bind-empty {
  display: none !important
}

.w-condition-invisible {
  display: none !important
}

canvas {
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  transform: none !important;
}

.video-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: static;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1.04rem;
  font-size: 1.2rem;
  font-weight: 300;
  border-bottom: 0.1px solid #c7c7c7 !important;
}

#video-container {
  background: #2c2c2cff;
  height: 85% !important;
  max-width: 100%;
}

.stream-video-container {
  position: absolute !important;
  height: 100%;
  top: 0;
  background: #2c2c2cff;
}

.video-player-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 7rem;
}

.video-player-location-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.video-player-map {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #383838;
  margin-top: 1rem;
}

.video-player-info-wrap {
  padding-left: 2rem !important;
}

.video-player-user-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0rem;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #383838;
  text-decoration: none;
  cursor: pointer;
}

.video-player-location-image {
  position: relative;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  margin-right: 1.1rem;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
}

.video-player-user-wrap:hover {
  color: #82a0f3;
}

.video-player-user-wrap-address {
  display: flex;
}

.video-player-user-info-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 85%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-player-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 0.7rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  white-space: nowrap;
}

.video-player-info-block {
  margin-top: 1.5rem;
}

.video-player-info.last {
  margin-right: 0rem;
}

.video-player-info.wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #fff;
}

.video-player-info-mark {
  background-color: hsla(0, 0%, 100%, 0.02);
  color: #fff;
}

.regular {
  font-weight: 400;
}

.video-player-location-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.user-avatar {
  position: relative;
  overflow: hidden;
  width: 20%;
  margin-right: 1.1rem;
  padding-top: 20%;
  border-radius: 100px;
  background-color: #e9e9e9;
  -o-object-fit: cover;
  object-fit: cover;
}

.user-avatar.small {
  width: 3rem;
  height: 3rem;
  padding-top: 0%;
  color: #fff;
}

.video-player_user-info_wrap {
  margin-left: 1rem;
}

.user-initials {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
}

.video-player-user-icon {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  display: none;
  width: 100%;
  height: 100%;
}

.video-player-user-info-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 85%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.video-player-info-title {
  margin-right: 0.5rem;
  color: #fff;
}

.location-list-image {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 48px;
  height: 48px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.video-card-info-wrap {
  margin-top: 10px;
}

.video-card-name-wrap {
  margin-bottom: 0.31rem;
}

.location-list-name {
  margin-top: 0px;
  margin-bottom: 0.31em;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.location-list-name:hover {
  margin-top: 0px;
  margin-bottom: 0.31em;
  font-size: 1.2rem;
  font-weight: 600;
  color: #82a0f3;
  cursor: pointer;
}

.w-inline-block {
  max-width: 100%;
  width: 100%;
  display: flex;
}

.html-embed-5 {
  width: 1.7rem;
  margin-right: 0.6rem;
}

.div-block-8 {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}

.div-block-9 {
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 4rem;
}

.html-embed-6 {
  width: 100%;
  height: 100%;
}

.page-header-wrap {
  padding-right: 3.65rem;
  padding-left: 3.65rem;
  max-width: 100%;
  overflow-x: hidden;
}

.page-header-back-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  color: #9b9b9c;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.page-header-back-icon:hover {
  cursor: pointer;
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

#page-header-back-icon:hover>#video-breadcrumb-title {
  cursor: pointer;
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.page-header-delete-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  color: #9b9b9c !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  float: right;
}

.page-header-delete-icon:hover {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.page-header-share-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #9b9b9c !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  float: right;
}

.page-header-share-icon:hover {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.function_wrap {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  cursor: pointer;
  right: 6rem;
  margin-top: 0.25rem;

  .loading-animation {
    height: 1.3rem;
  }
}

.function_wrap:hover {
  color: #fff;
}

.share-url {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #9b9b9c;
}

.share-url:hover {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffffff;
}

.share-image {
  width: 1rem;
}

.share-image:hover {
  width: 1rem;
  color: #ffffff;
}

.page-modal-delete-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.2rem;
}

.location-indicator {
  display: flex;
}

.location-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  color: #fff !important;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 1rem;
}

a:-webkit-any-link {
  margin-top: 0px !important;
  margin-bottom: 0.31em !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #fff;
  cursor: pointer !important;
  text-decoration: auto !important;
  font-family: 'Open Sans';
}

#dropdown-custom-components {
  color: #fff !important;
}

.loc-item {
  color: #171717 !important;
  text-decoration: auto !important;
}

.video-preview-location-info {
  margin-top: 0.5rem;
  margin-bottom: 1em;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
}

.pop-up_confirm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pop-up_delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
}

.text-span-10 {
  color: #000;
  font-weight: 700;
}

.delete-button {
  margin-top: 1.04rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  background-color: #db013d;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  font-size: 1rem;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #a8002e;
}

.btn-danger:disabled {
  background-color: #db013d;
}

.left-nav-dark {
  min-height: 100%;
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 200px;
  max-width: 200px;
  background-color: #1b1a1a !important;

  &.flex-between {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
}

.full-width-content-page-dark {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4.5rem;
  padding-bottom: 3.65rem;
  padding-left: 4.5rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #1b1a1a !important;
}

.page-header-wrap-dark {
  overflow-x: hidden;
}

.video-breadcrumb-title {
  color: #9b9b9c !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: 'Open Sans';
}

#video-breadcrumb-title:hover>div#page-header-back-icon {
  color: #fff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

.video-breadcrumb-title:hover {
  color: #fff !important;
}

.nav-link-dark {
  width: 100%;
  margin-right: auto;
  margin-bottom: 1rem;
  padding: 1.04rem 1.04rem 1.04rem 2.5rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: grey !important;
  font-size: 1rem !important;
  cursor: pointer;
}

.nav-link-dark:hover {
  background-color: #333 !important;
  color: #fafafa !important;
  cursor: pointer;
}

.header-back {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1.04rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0.5px solid #aaa9aa;
  font-size: 1.2rem;
  font-weight: 600;

  .darkmode {
    position: static;
    border-bottom-color: #aaa9aa;
    color: #aaa9aa;
    font-weight: 300;
    padding-right: 0rem;
  }

  .text-block-4 {
    color: #aaa9aa;
    font-weight: 400;
  }
}

.video-player-library-area {
  padding-right: unset !important;
  overflow: hidden;
}

.video-player-library {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 0.5px solid #4f4f4e;
  border-radius: 4px;
  background-color: #1d1d1d;
}

.video-player_library-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.5rem 1.2rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 0.5px solid #4f4f4e;
}

.text-block-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #aaa9aa;
  font-size: 0.8rem;
  line-height: 1.04rem;
  font-weight: 400;
  cursor: default;
}

.video-player_library-list {
  position: relative;
  overflow: hidden;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.video-player_library_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1.2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  cursor: pointer;
}

.video-player_library_item:hover {
  background-color: hsla(0, 0%, 100%, 0.02);
}

.video-player_item-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 1.5em;
  padding-right: 1.2em;
  padding-bottom: 1.5em;
  border-radius: 4px;
}

.form-block {
  width: 100%;
  margin-left: 1.1rem;
}

.textarea {
  padding: 1.1rem;
  border: 0.5px solid #4f4f4e;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0);
  color: #fff;
}

.textarea:focus-visible {
  border-color: #6950fc;
}

.textarea[data-wf-focus-visible] {
  border-color: #6950fc;
}

.date {
  margin-right: 0px;
  margin-left: auto;
  flex: 0 0 auto;
  color: #aaa9aa;
  font-size: 0.8rem;
  line-height: 1.04rem;
  font-weight: 400;
  position: relative;
  left: 0;
  top: 1rem;
}

.video-player_item-thumbnail-img {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -o-object-fit: none;
  object-fit: contain;
}

.time-stamp {
  margin-top: 1rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 1rem;
  line-height: 1.04rem;
  font-weight: 400;
  text-align: left;
}

.div-block-16 {
  width: 100%;
  font-family: "Open Sans";
  margin-left: 1.1rem;
  color: #fff;
  font-size: 1rem;
}

.video-player_tag {
  width: 2em;
  padding-top: 1.5rem;
  color: #6950fc;
}

.html-embed-9 {
  width: 1rem;
  margin-right: 0.31rem;
  cursor: pointer;
}

.html-embed-9:hover {
  color: #fff;
}

.video-player_timeline {
  position: relative;
  height: 100px;
  margin-top: 3rem;
  margin-right: auto;
  margin-left: auto;
}

.play-button {
  width: 1.5em;
  margin-top: 10px;
  margin-right: 1.5em;
  margin-bottom: 10px;
  color: #707070;
}

.play-button:hover {
  color: #fff;
}

.video-plauer_item-thumbnail {
  position: relative;
  margin-bottom: 1rem;
  padding-top: 56.25%;
}

.video-player_item-screenshot {
  width: 40%;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}

.timeline_mark {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 0.5px dashed #4f4f4e;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.div-block-23 {
  z-index: 1;
  width: 100%;
  border: 1px solid #391579;
  border-radius: 4px;
  background-color: #beb2ff;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8rem;
}

.time-indicator {
  margin-left: -18px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #c8c8c8;
  cursor: pointer;
}

.hover-indicator {
  max-width: 99.993%;
  display: block;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  background: unset;
  width: 100%;
}

.div-block-25 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-top: 5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.left {
  position: absolute;
  left: -2%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 7px;
  margin-left: 7px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.right {
  position: absolute;
  left: auto;
  top: 0%;
  right: -2%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.div-block-26 {
  position: absolute;
  left: 10%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-26._2 {
  left: 32%;
}

.div-block-26._3 {
  left: 42%;
}

.image-9 {
  position: relative;
  top: -23px;
}

.no-cameras {
  margin-top: 10px;
  margin-bottom: 0.31rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: grey;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.please-turn-on {
  display: block;
  margin-bottom: 0.31rem;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: grey;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
}

.image-10 {
  width: 50%;
}

.no-cameras-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collection-list-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-27 {
  height: 100%;
  border-left: 1px solid #6950fc;
}

.video-player_user_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.video-player_user_wrap:hover {
  color: #82a0f3;
}

.video-player-user-info-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.31rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.location-name-text {
  .location-list_name {
    margin-top: 0px;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    color: #e9e9e9 !important;
  }
  .location-list_name:hover {
    color: #82a0f3;
  }
}


.textarea {
  padding: 1.1rem !important;
  border: 0.5px solid #4f4f4e !important;
  border-radius: 4px !important;
  background-color: hsla(0, 0%, 100%, 0) !important;
  color: #fff !important;
}

.textarea:focus-visible {
  border-color: #6950fc !important;
}

.textarea[data-wf-focus-visible] {
  border-color: #6950fc !important;
}

.textarea::placeholder {
  font-size: 0.9rem !important;
  font-family: 'Open Sans', sans-serif;
}

.video-button_submit-comment {
  border-radius: 4px;
  background-color: #6950fc;
  font-size: 0.8rem;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.video-button_submit-comment:hover {
  background-color: #5b3fff;
}


.video-w-button {
  display: inline-block;
  padding: 0rem 1rem;
  background-color: #6950fc;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}


.simple-link-box {
  color: #333;
  font-size: 0.8rem;
  text-decoration: none;
}

.simple-link-box:hover {
  text-decoration: underline;
}

.simple-link-box.dark-mode {
  color: #aaa9aa;
  background-color: unset !important;
}

.simple-link-box.dark-mode.margin_right_1em {
  margin-left: 1em;
  font-size: 0.8rem;
}

.div-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-player_item-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  color: #aaa9aa;
}

.loc_item-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.1rem;
  margin-left: 1rem;
  color: #fff;
}

.loc-edit {
  color: #fff;
}

.loc-edit:hover {
  color: #fff;
}

.loc-edit:hover>.loc_item-action {
  color: #fff;
}

.dropdown-toggle {
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}

.dropdown-toggle:focus {
  background-color: #1b1a1a;
  border-color: #1b1a1a;
}

.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.drop-down-toggle {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex;
  padding: 0px !important;
  color: #fff !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  background-color: #1b1a1a !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: 0px !important;
  margin-bottom: 0.31em !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #fff !important;
  cursor: pointer !important;
}

.dropdown-menu {
  margin-top: 2%;
}

.drop-down-item {
  padding: 10px 20px;
  display: block;
  color: #222222;
}

.dropdown-menu.show {
  margin-top: 1rem !important;
}

.loc-action-area {
  display: flex;

  .location-list_name {
    margin-top: 0px ;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    cursor: pointer;
  }

  .location-list-name:hover {
    color: #82a0f3 !important;
  }
}

*:focus {
  outline: none !important;
}

// Media CSS 

@media screen and (max-width:767px) {
  .page-header-wrap {
    padding: 1rem;
    margin: 0;
  }

  .video-player-wrap {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
  }

  .page-header {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .video-player-info-wrap {
    max-width: unset !important;
    margin-top: 3rem !important;
    padding-left: 1rem !important;
  }

  .video-player-library {
    margin-top: 6rem !important;
  }

  .full-width-content-page-dark {
    border-left: none !important;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: -3rem;
  }

  .page-header-wrap-dark {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  #video-container {
    height: 15rem;
  }

  .video-player {
    position: relative !important;
    height: 15rem !important;
  }

  .video-page-header {
    margin-bottom: unset !important;
  }

  .video-player-library-area {
    padding-right: unset !important;
    padding-left: unset !important;
    margin-top: 1.5rem !important;
    overflow: hidden;
  }
  .date {
    position:unset !important;
  }
}

@media only screen and (min-width : 768px) and (max-width: 990px) {
  .video-player-info-wrap {
    width: 100% !important;
    max-width: 1000px !important;
    margin-top: 5rem !important;
    padding-left: 0rem !important;
  }

  .regular {
    font-weight: 400;
    width: 100%;
    text-align: right;
  }

  .video-player-wrap {
    margin-top: 0 !important;
    padding-left: 0rem;
    padding-right: 0rem;
    margin: 0;
    margin-bottom: 1rem !important;
  }

  .page-header-wrap-dark {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  #video-container {
    height: 30rem !important;
  }

  .video-player {
    position: sticky;
    top: 0rem;
    display: inline-block;
    padding-bottom: 0rem;
    border: 0.5px solid #4f4f4e;
    border-radius: 4px;
    background-color: #1d1d1d;
    cursor: pointer;
  }

  .video-player-library-area {
    padding-right: unset !important;
    padding-left: unset !important;
    margin-top: 2rem !important;
    width: 100% !important;
    overflow: hidden;
  }
  .user-avatar.small{
    width: 10rem;
    height: 10rem;
    max-width: 3rem;
    max-height: 3rem;
    padding-top: 0%;
    color: #fff;
    margin-left: 50%;
    right: 0;
  }
}

// @media screen and (max-width:991px) and (max-width: 1100px) {
//   .page-header-wrap {
//     padding: 1rem;
//     margin: 0;
//   }

//   .video-player-wrap {
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -webkit-flex-direction: column;
//     -ms-flex-direction: column;
//     flex-direction: column;
//   }

//   .page-header {
//     margin: 0;
//   }

//   .video-player-info-wrap {
//     max-width: unset !important;
//   }

//   .full-width-content-page-dark {
//     border-left: none !important;
//     padding-left: 1rem;
//   }
// }