
html {
  font-size: 16px;
}

body {
  font-family: 'Open Sans', 'sans-serif';
  color: #333;
  font-size: 14px;
}

.app-body {
  font-family: 'Open Sans', 'sans-serif';
  color: #333;
  font-size: 14px;
  min-height: 100vh;
  background-color: #fff;
  max-width: 100%;
  margin: 0;
}

.paragraph {
  color: #eee !important;
}

.navbar {
  background-color: #2c2c2c !important;
}

h1 {
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h3 {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}

p {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}

blockquote {
  font-family: "Open Sans";
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

pre {
  font-family: "Open Sans";
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  font-size: 0.9rem;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.top-nav-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-right: 3.65rem;
  padding-left: 3.65rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 4.15rem;
  background-color: #2c2c2c !important;
}

.top-nav-wrap.middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.top-nav-link {
  margin-bottom: 0px !important;
  margin-top: 0.2rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: #d6d5d7;
  cursor: pointer;
  white-space: nowrap;
}

.navbar-brand {
  margin-left: 1rem;
  font-size: 1rem;
}

.top-nav-link:hover {
  color: #fff;
}

.top-nav-link.w--current {
  color: #fff;
}

.nav-link-3 {
  color: #fff;
}

.nav-link-4 {
  color: #fff;
}

.activate-content {
  height: 86vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.activate-content-image {
  width: 8rem;
  top: 0;
  animation: activeImageAnimate 0.5s linear infinite;
  animation-iteration-count: 1;
  /*animation: animation-name animation-duration animation-direction animation-iteration-count */
  transition: .5s ease-in-out;
}

.activate-content-row {
  display: block;
}

.activate-content-pinCode {
  width: 20rem;
  margin-top: 2.08rem !important;
  padding: 1.4em 1.4rem !important;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 2rem!important;
  font-weight: 300;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin: auto;
  margin-bottom: 2rem;
}

.activate-content-error {
  margin-top: 2.08rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  font-family: 'Open Sans';
  margin: auto;
  height: 3.5rem;
  font-size: 1rem;
}

.w-input,
.w-select {
  display: block;
  height: 38px;
  margin-bottom: 10px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
}

.w-input:focus,
.w-select:focus {
  border-color: #3898EC;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  outline: 0;
}

.activate-content-button {
  margin-top: 1.04rem;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  background-color: #292876;
  border-color: #292876;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  font-size: 1.09rem;
  cursor: pointer !important;
  line-height: normal;
}

.activate-content-button:focus {
  margin-top: 1.04rem;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  background-color: #292876;
  border-color: #292876;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  font-size: 1.09rem;
  cursor: pointer;
}

.activate-content-button:hover {
  background-color: #3432b9;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.activate-content-modal {
  max-width: 690px;
}

.activate-content-modal-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.activate-content-modal-close {
  margin: auto;
}

.modal-content {
  max-width: 690px;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
}

.modal-body {
  margin-top: 1rem;
  padding-top: 1rem;
}

.modal-footer {
  border-top: none;
  padding: 0;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1rem;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(15%) sepia(49%) saturate(2329%) hue-rotate(223deg) brightness(95%) contrast(99%);
}

.brand-dark {
  filter: unset !important;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.brand:hover {
  opacity: 1;
}

.brand.w--current {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-toggler {
  border: none;
  color: none;
  border-color: none !important;
  padding: 5px;
  height: 3.45rem;
  background-color: #2c2c2c;
  border-radius: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  top: 0;
  position: absolute;
  right: 0;
}

.navbar-toggler collapsed {
  border: none;
  color: none;
  border-color: none;
  padding: 5px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}


.tulu-nav-active {
  .toggle-icon-mobile {
    color: #FFFFFF;
    font-size: 1.5rem;
  }

  &.active {
    border-radius: 0;
    background-color: #FFFFFF;

    .toggle-icon-mobile {
      color: #212529;
    }
  }
}

.navbar-toggler-icon:focus {
  color: #212529;
}

.left-nav {
  min-height: 100%;
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 200px;
  max-width: 200px;
  background-color: #fff !important;

  &.flex-between {
    min-height: 100vh;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }
}

.tulu-logout {
  position: fixed;
  top: auto;
  bottom: 0;
	margin-bottom: 3rem;
  width: 12.5rem;
}

.navbar-wrap {
  position: fixed;
  left: 0%;
  padding-top: 2.5rem;
  top: 0px;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 12.5rem;
  z-index: 999;
  background-color: white;
  border-right: 1px solid #c7c7c7;
}

.video-navbar-wrap {
  background: #1B1A1A 0% 0% no-repeat padding-box !important;
  border-right: 1px solid #525252 !important;

  .image-link {
    margin-right: auto;
    padding: 0rem 1.5rem 0rem 1.5rem !important;
  }

  .nav-link {
    padding: 1.04rem 1.5rem 1.04rem 1.5rem !important;
  }

  .nav-link:hover {
    background-color: #525252;
    color: #fff;
    width: 99%;
  }

  .image-2 {
    width: 4rem !important;
  }
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2.5rem;
  background-color: hsla(0, 0%, 100%, 0);
}

.nav-link {
  width: 100%;
  margin-right: auto;
  margin-bottom: 1rem;
  padding: 1.04rem 1.04rem 1.04rem 2.5rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: grey;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.nav-link:hover {
  background-color: #fafafa;
  color: #333;
  width: 99%;
}

.nav-link-icon {
 width: 100%;
 height: 100%; 
}

.nav-link-icon-div {
  width: 1.2rem;
  margin-right: 0.5rem;
}

.nav-link.w--current {
  color: #313131;
  font-weight: 600;
}

.nav-link.dark:hover {
  background-color: hsla(0, 0%, 100%, 0.02);
  color: #fff;
}

.image-2 {
  width: 5rem;
}

.image-link {
  margin-right: auto;
  padding: 0rem 1.04rem 0rem 2.5rem;
}

.nav-link {
  text-decoration: none !important;
  font-family: 'Open Sans', sans-serif;
  color: grey;
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
  font-size: 1rem !important;
  font-weight: 600 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem !important;
  padding: 1.04rem 2.5rem !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: grey;
  text-decoration: none;
  cursor: pointer;
}

.nav-link:visited {
  text-decoration: none !important;
  font-family: 'Open Sans', sans-serif;
  color: grey;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  margin-top: 0rem;
  padding-top: 1rem;
  border-right: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
  color: gray;
  position: absolute;
  top: 3.45rem;
  width: 12.5rem !important;
  z-index: 1;
}

.mobile-nav {
  height: 3.5rem;
  border-bottom: 1px solid #c7c7c7;
}

.mobile-link {
  color: gray !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  font-size: 0.9rem;
}

.mobile-link:focus {
  color: #313131 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  font-size: 0.9rem;
}

.page-header {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1.04rem;
  border-bottom: 1px solid #c7c7c7;
  font-size: 1.2rem;
  font-weight: 600;
}

.content-page {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  padding-bottom: 2rem;
  border-left: 1px solid #c7c7c7;
  background-color: #fff;
}

.full-width-content-page {
  background-color: #fff;
  // padding-bottom: 13rem;
}

.breadcrumb-title {
  color: #313131;
  text-decoration: none;
}

.breadcrumb {
  margin-bottom: 0 !important;
  display: unset;
}

.back-area {
  position: absolute;
  display: grid;
  left: -3.3rem;
  text-align: center;
  top: -0.5rem;

  .back-title {
    text-align: left;
    font: normal normal normal 18px/14px Open Sans;
    letter-spacing: 0px;
    color: #9C9B9D;
    margin-top: 0.5rem;
    display: none;
  }

  .back-icon {
    margin-left: 0.5rem !important;
  }

  .back-icon:hover ~ .back-title {
    display: block !important;
    text-align: left;
    font: normal normal normal 18px/14px Open Sans;
    letter-spacing: 0px;
    color: #fff;
    margin-top: 0.5rem;
  }
}

.action-area {
  position: absolute;
  display: flex;
  left: 1rem;
}

.action-area:hover {
  .video-breadcrumb-title {
    color: #fff !important;
  }

  .page-header-back-icon {
    color: #fff !important;
  }
}

.video-card-name-wrap {
  margin-bottom: 0.31rem !important;
}

.video-card-person {
  display: block;
  margin-bottom: 0.31rem;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: grey !important;
  font-size: 0.8rem;
  text-decoration: none !important;
  cursor: pointer !important;
  margin-top: 0.31rem !important;
}

.video-card-person:hover {
  color: #82a0f3 !important;
}

.video-card-name-link {
  margin-bottom: 0.31rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: #333 !important;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none !important;
  cursor: pointer !important;
}

.video-card-name-link:hover {
  color: #82a0f3 !important;
}

.video-card-video-wrap {
  overflow: hidden;
  border: none;
}

.video-card-time-wrap {
  padding: unset !important;
  margin-top: -0.1rem;
  .item-row {
    width: 100%;
    display: flex;
    margin-right: 0;
    margin-left: 0;
    border: 1px solid #dbdbdb;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    .video-card-duration {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-right: 1px solid #dbdbdb;
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      cursor: default;
    }
    
    .video-card-duration.live {
      padding-left: 0rem;
    }
    
    .video-card-times {
      display: flex;
      padding: 0.73rem 0.83rem;
      font-family: "Open Sans", sans-serif;
      color: grey;
      font-size: 1rem;
      line-height: 1.04rem;
      font-weight: 600;
      cursor: default;
    }
    
    .video-card-times-label {
      margin-bottom: 0 !important;
    }
  }
}

.video-card-thumbnail-wrap {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.video-card-thumbnail {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.live {
  color: #db013d;
  margin-bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;

  .live-icon {
    margin-right: 0.5rem;
    padding-bottom: 0.1rem;
  }
}

.live-stream-indicator {
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.record {
  margin-bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;

  .live-icon {
    margin-right: 0.5rem;
    padding-bottom: 0.1rem;
  }
}

.live-indicator {
  right: auto;
  bottom: auto;
  width: 100%;
  height: 1.5rem;
  margin-right: 0.3rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  max-height: 1.125rem;
  max-width: 1.125rem;
}

.connecting-indicator {
  right: auto;
  bottom: auto;
  width: 100%;
  height: 2.5rem;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  max-height: 3.125rem;
  max-width: 2.125rem;
  z-index: 99;
}


#main-live-loading {
  height: 6vh;
}

.recorded {
  margin-bottom: 0;
}

.time-label {
  margin-bottom: 0;
  margin-top: 0.2rem;
  margin: auto;
  font-size: 14px;
  font-size: 1vw;
}

.live-dot {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
  border-radius: 20px;
}

.video-card-overlay-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(31, 31, 31, 0.56);
  opacity: 0;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}

.video-card-overlay-wrap:hover {
  opacity: 1;
}

.video-card-thumbnail-wrap-empty {
  position: relative;
  overflow: hidden;
  padding-top: 58%;
  padding-bottom: 2.65rem;
  border: 1px dashed #dbdbdb;
  border-radius: 4px;
}

.video-card-date {
  color: grey;
  font-size: 0.8rem;
  margin-top: 0.7rem;
  font-weight: 400;
  cursor: default;
}

.video-card-date.divider {
  margin-right: 0.4rem;
  margin-left: 0.2rem;
}

.video-player {
  position: sticky;
  top: 2.5rem;
  display: flex;
  border: 0.5px solid #4f4f4e;
  border-radius: 4px;
  background-color: #1d1d1d;
  cursor: pointer;
  padding-right: 0rem;
  padding-left: 0rem;
  height: 50rem;
}

.video {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 13%;
  right: auto;
  bottom: auto;
}

.video-card-no-camera {
  padding-right: 1.4rem;
  padding-left: 1.4rem;
}

.video-card-no-camera-wrap {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  padding-bottom: 2.65rem;
}

.video-card-loading {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2c2c2c;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: grey;
  font-size: 0.8rem;
  text-decoration: none;
}

.video-card-overlay-wrap-copy {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(31, 31, 31, 0.56);
  opacity: 0;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
}

.video-card-overlay-wrap-copy:hover {
  opacity: 1;
}

.video-card-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.video-card-live-overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.video-card-duration-text {
  margin-left: 0.83rem;
}

.video-card-duration-text {
  margin-left: 0.83rem;
}

.Video-card-footer {
  margin-top: 10px;
  background: white;
  border: none;
  padding: 0;
}

.card-img-top {
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  object-fit: cover;
}

.card-img-top:hover {
  cursor: pointer;
}

.card-img-expand {
  height: 99%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(31, 31, 31, 0.56);
  opacity: 0.8;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  top: 0;
}

.video-img-expand {
  height: 85%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(31, 31, 31, 0.56);
  opacity: 0.8;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  top: 0;
}

.success-badge {
  right: 1rem;
  left: auto;
  position: absolute;
  margin-top: 1rem;
  z-index: 9;
  font-weight: 400;
  cursor: pointer;

  .success-icon {
    margin-right: 0.3rem;
  }
}

.failed-badge {
  right: 1rem;
  left: auto;
  position: absolute;
  margin-top: 1rem;
  z-index: 9;
  font-weight: 400;
  cursor: pointer;

  .failed-icon {
    margin-right: 0.3rem;
  }
}

.redo-badge {
  right: 4.8rem;
  left: auto;
  position: absolute;
  margin-top: 1rem;
  z-index: 9;
  font-weight: 400;
  cursor: pointer;

  .redo-icon {
    margin-right: 0rem;
  }
}

.card-img {
  cursor: pointer !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  min-height: 11.65rem;
}

.card-expand-icon {
  margin-right: 1rem;
  cursor: pointer;
}

.card-expand-label {
  margin-bottom: 0;
  cursor: pointer;
}

.card-connecting-label {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2c2c2c;
  -webkit-transition: opacity 50ms ease;
  transition: opacity 50ms ease;
  color: grey;
  font-size: 0.8rem;
  text-decoration: none;
  padding-top: 5rem;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.activity-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 2.5rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 0.83rem;
  grid-row-gap: 3rem;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.video-card-thumbnail-wrap-empty {
  position: relative;
  overflow: hidden;
  padding-top: 58%;
  padding-bottom: 2.65rem;
  border: 1px dashed #dbdbdb;
  border-radius: 4px;
}

.no-cameras-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-10 {
  width: 50%;
}

.no-cameras {
  margin-top: 10px;
  margin-bottom: 0.31rem;
  -webkit-transition: color 100ms ease;
  transition: color 100ms ease;
  color: grey;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.please-turn-on {
  display: block;
  margin-bottom: 0.31rem;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: grey;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .activity-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .container {
    margin-right: 0px;
    margin-left: 0px;
  }

  .location-header_contact {
    font-size: 1rem;
  }

  .navbar {
    width: 100%;
    max-width: none;
    background-color: #2c2c2c;
  }

  .navbar-wrap {
    margin-top: 0rem;
    padding-right: 3.65rem;
    padding-left: 3.65rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .nav-menu {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 0rem;
    padding-top: 1rem;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    border-left: 1px solid #c7c7c7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
  }

  .video-navbar-wrap {
    display: none !important;
  }

  .action-area {
    display: none;
  }

  .brand.w--current {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .location-list_wrap {
    width: 100%;
    max-width: none;
  }

  .location-list_search-wrap {
    top: 6.6%;
  }

  .large-map {
    display: none;
  }

  .menu-button {
    right: -18px;
    background-color: #2c2c2c;
    color: #fff;
  }

  .menu-button.w--open {
    background-color: #fff;
    color: #000;
  }

  .video-player {
    margin-bottom: 1.5rem;
  }

  .video-player_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .video-player_info_wrap {
    padding-left: 0rem;
  }

  .video-player-info {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 1rem;
  }

  .top-nav-wrap {
    margin-right: 0px;
    margin-left: 0px;
  }

  .nav-link-icon {
    height: 1rem;
    padding-right: 0.5rem;
    width: unset;
  }
}

@media screen and (max-width: 767px) {
  .activity-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .container {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }

  .content-page {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    border-left: 1px solid #c7c7c7;
    background-color: #fff;
    margin-top: 5rem;
  }

  .full-width-content-page {
    top: 3.4rem;
    position: absolute;
    width: 100%;
    padding-bottom: 3rem;
  }

  .navbar-brand {
    margin-left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    left: 0;
  }

  .container.middle {
    padding-top: 5rem;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .video-card-times {
    display: flex;
    padding: 0.73rem 0.83rem;
    font-family: "Open Sans", sans-serif;
    color: grey;
    font-size: 1rem;
    line-height: 1.04rem;
    font-weight: 600;
    cursor: default;
    width: 66%;
  }

  .location-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .location-header_image-ratio {
    width: 6rem;
    height: 6rem;
    margin-right: 0rem;
    margin-bottom: 1.56rem;
  }

  .location-header_info-wrap {
    width: 100%;
  }

  .location-header_title {
    margin-bottom: 1.56rem;
    text-align: center;
  }

  .location-header_contact-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .location-header_contact {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navbar-wrap {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }

  .container-copy {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }

  .video-player-info {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .pop-up_screen {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }

  .top-nav-wrap {
    padding-right: 1.8rem;
    padding-left: 1.8rem;
  }

  .top-nav-wrap.middle {
    padding-top: 5rem;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .menu-button-2 {
    color: #fff;
  }

  .video-navbar-wrap {
    display: none !important;
  }

  .action-area {
    display: none;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width: 990px) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .container.dark-mode {
    padding-bottom: 2.5rem;
  }

  .location-header {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .content-page {
    left: 0;
    position: absolute;
    top: 4rem;
    height: 71rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    width: 100%;
  }

  .video-navbar-wrap {
    display: none !important;
  }

  .action-area {
    display: none;
  }

  .video-player-wrap {
    display: flex;
    justify-content: center;
    overflow-x: hidden;
  }

  .video-player {
    position: sticky;
    display: inline-block;
    border: 0.5px solid #4f4f4e;
    border-radius: 4px;
    background-color: #1d1d1d;
    cursor: pointer;
    width: 100%;
}

  .video-player_wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .video-card-times {
    display: flex;
    padding: 0.73rem 0.83rem;
    font-family: "Open Sans", sans-serif;
    color: grey;
    font-size: 1rem;
    line-height: 1.04rem;
    font-weight: 600;
    cursor: default;
    width: 69%;
  }

  .video-player_info_wrap {
    padding-left: 0rem;
  }

  .video-player-info {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 1rem;
  }

  .page-header-wrap {
    padding-right: 1rem;
    padding-left: 2rem;
  }

  .full-width-content-page-dark {
    position: absolute;
    width: 100%;
  }

  .full-width-content-page {
    top: 3.4rem;
    position: absolute;
    width: 100%;
    padding-bottom: 3rem;
  }

  .location-header_contact-wrap {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 16px;
  }

  .location-header_contact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    white-space: normal;
  }

  .location-header_contact.wrap {
    white-space: normal;
  }

  .location-header_icon {
    white-space: normal;
  }

  .location-header_link {
    white-space: nowrap;
  }

  .location-header_people_wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .navbar-wrap {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .location-list_address {
    font-size: 1rem;
  }

  .container-copy {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .activation-wrap {
    width: auto;
    margin-right: 0px;
    margin-left: 0px;
  }

  .text-input-field {
    width: auto;
    font-size: 1.5rem;
  }

  .image-2 {
    width: 4rem;
  }

  .video-player_wrap {
    margin-top: 2.5rem;
  }

  .video-preview_location-info {
    font-size: 1rem;
  }

  .top-nav-wrap {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .top-nav-wrap.dark-mode {
    padding-bottom: 2.5rem;
  }

  .location-map {
    height: 200px;
  }

  .nav-link-icon {
    height: 1rem;
    padding-right: 0.5rem;
    width: unset;
  }

}

@media screen and (max-width: 479px) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .container.dark-mode {
    padding-bottom: 2.5rem;
  }

  .location-header {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .location-header_contact-wrap {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 16px;
  }

  .location-header_contact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    white-space: normal;
  }

  .location-header_contact.wrap {
    white-space: normal;
  }

  .location-header_icon {
    white-space: normal;
  }

  .location-header_link {
    white-space: nowrap;
  }

  .location-header_people_wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .navbar-wrap {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .video-navbar-wrap {
    display: none !important;
  }

  .action-area {
    display: none;
  }

  .location-list_address {
    font-size: 1rem;
  }

  .container-copy {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .activation-wrap {
    width: auto;
    margin-right: 0px;
    margin-left: 0px;
  }

  .text-input-field {
    width: auto;
    font-size: 1.5rem;
  }

  .image-2 {
    width: 4rem;
  }

  .video-player_wrap {
    margin-top: 2.5rem;
  }

  .video-preview_location-info {
    font-size: 1rem;
  }

  .top-nav-wrap {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .top-nav-wrap.dark-mode {
    padding-bottom: 2.5rem;
  }

  .location-map {
    height: 200px;
  }
  .nav-link-icon {
    height: 1rem;
    padding-right: 0.5rem;
    width: unset;
  }
}

@keyframes activeImageAnimate {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

@media not all and (min-resolution:.001dpcm) {
  .video-card-video-wrap {
    overflow: hidden;
    border: none;
    max-width: 20rem;
    max-height: 20rem;
  }
}