.tulu-component-manage {
    padding: 30px 60px !important;

    .tabs {
        width: 100%;
        display: flex;
        align-items: baseline;
        border-bottom: 1px solid #C7C7C7;

        .btn-tab {
            padding: 21px 61px;
            color: #000000;
            letter-spacing: 0px;
            font: normal normal 600 20px/13px "Open Sans";
            border-radius: 0;

            &.active {
                border-bottom: 2px solid #6950FC;
            }

            &:focus {
                box-shadow: none !important;
            }
        }
    }

    .table-wrapper {
        max-width: 700px;
        overflow: scroll;
    }

    table {
        width: 100%;
        position: relative;
        border-collapse: collapse;

        tr {
            height: 50px;

            td, th {
                color: #000000;
                padding: 15px 20px;
                white-space: nowrap;
                letter-spacing: 0px;
                vertical-align: middle;
                border: 1px solid #C7C7C7;
                font: normal normal normal 15px/13px Open Sans;
            }

            th {
                font-weight: bold;
                background-color: #F4F4F3;
            }

            td {
                &.active {
                    padding: 0 1px;
                    height: 50px;

                    input {
                        margin: 0;
                        height: 50px;
                        z-index: 100;
                        border-radius: 0;
                        position: relative;
                        border: 1px solid #6950FC !important;

                        &:focus {
                            box-shadow: none !important;
                        }
                    }
                }
            }

            .row-num {
                cursor: pointer;
            }

            &.active {
                td {
                    padding: 0 1px;
                    height: 50px;

                    input {
                        margin: 0 1px;
                        height: 50px;
                        border: 0 !important;
                        position: relative;

                        &:focus {
                            box-shadow: none !important;
                        }
                    }
                }
            }

            &:first-child {
                td {
                    border-style: dashed;

                    &:last-child {
                        border-right: 1px solid #C7C7C7;
                    }
                    &:first-child {
                        border-left: 1px solid #C7C7C7;
                    }
                }
            }
        }

        .btn-danger {
            width: 100%;
            margin-left: 1px;
            margin-right: 1px;

            svg {
                font-size: 1.2rem;
            }
        }

        .btn-success {
            width: 100%;
            font-size: 1rem;
            background: #6950fc;
            border-color: #6950fc;
            margin-left: 1px;
            margin-right: 1px;

            svg {
                font-size: 1.2rem;
            }
        }

        .btn-add {
            padding: 0;
            color: #04BE6D;
            text-align: left;
            letter-spacing: 0px;
            text-decoration: none;
            font: normal normal normal 15px/13px Open Sans;
    
            &:focus {
                box-shadow: none !important;
            }
        }

        .action-column {
            display: flex;
            align-items: center;
        }

        .add-icon {
            color: #04BE6D;
            letter-spacing: 0px;
            font: normal normal 600 15px/13px Open Sans;
        }
    }

    .tulu-btn-light {
        color: #323333;
        border-radius: 4px;
        letter-spacing: 0px;
        padding: 10px 16px;
        height: 40px;
        border: 1px solid #C7C7C7;
        font: normal normal 600 15px/21px 'Open Sans' !important;
    }

    .tulu-primary {
        padding: 10px 16px;
        height: 40px;
        border-radius: 4px;
        letter-spacing: 0px;
        color: #FFFFFF;
        font: normal normal 600 15px/21px Open Sans;
        background: #292876 0% 0% no-repeat padding-box;
    }

    .manage-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 1rem 0 1rem;

        .select-client {
            min-width: 300px;
        }

        .actions {
            display: flex;

            .btn-primary {
                width: 100%;
                font-size: 1rem;
                background: #6950fc;
                border-color: #6950fc;
                svg {
                    font-size: 1rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}

.tulu-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;

    .pagination-box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        list-style: none;
        margin-right: 20px;
        margin-bottom: 0;

        li {
            padding-left: 10px;
            padding-right: 10px;

            text-align: left;
            font: normal normal bold 12px/15px "Source Sans Pro";
            letter-spacing: 2.4px;
            color: #9F9F9F;

            a {
                cursor: pointer;
            }

            &.active {
                text-decoration: underline;
                font: normal normal bold 12px/15px "Source Sans Pro";
                letter-spacing: 2.4px;
                color: #000000;
            }
        }
    }
}

.suggestions-box {
    position: absolute;
    z-index: 999;
    max-width: 100%;
    width: 100%;
    padding-right: 30px;
}

.suggestions {
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    top: 36px;
    user-select: none;
    background-color: #fbfbfb;
}

.suggestion {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    padding: 0 7px 0 10px;
    white-space: nowrap;

    &:hover {
        background: #f5f5f5;
    }
}

.suggestion:last-child {
    border: none;
}