.tulu-login-page {
    height: 100vh;
    
    .login-page {
        height: 100vh;
        padding: 0 !important;

        display: flex;
	    flex-direction: column;
    }

    .success-header {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 20px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        border-radius: 4px;
        background-color: #04bf6c;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
    }

    .custom-navbar {
        background-color: #2c2c2c;
        position: absolute;
        z-index: 1000;
        width: 100%;
        top: 0;
        bottom: auto;

        .top-nav-wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            height: auto;
            padding: 20px 3.65rem;

            .w-nav-brand {
                position: relative;
                float: left;
                text-decoration: none;
                color: #333333;
                background-color: transparent;
                font-family: 'Open Sans', sans-serif;
                font-size: 1vw;
                line-height: 20px;
            }

            .image-brand {
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
                width: 20%;
            }
        }

        &::before {
            content: " ";
            display: table;
            grid-column-start: 1;
            grid-row-start: 1;
            grid-column-end: 2;
            grid-row-end: 2;
        }
    }

    .login-body {
        flex-grow: 1;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .body-box {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            white-space: nowrap;
            font-size: 2rem;
            line-height: 1.2;
            font-weight: 600;
            text-align: center;
            margin-bottom: 3rem;
            width: 315px;
        }

        .tulu-input {
            width: 315px;
            margin-top: 1.2rem;
            margin-bottom: 0px;
            padding: 1.4em 1.4rem;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            font-size: 1rem;
            font-weight: 300;
            text-align: left;
            height: 46px;

            &:focus {
                outline: 0;
                box-shadow: unset;
                border-color: #3898EC;
            }
        }

        .login-btn {
            width: 315px;
            padding: 0.9rem 1rem;
            border-radius: 4px;
            background-color: #292876;
            -webkit-transition: opacity 50ms ease;
            transition: opacity 50ms ease;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                background-color: #3432b9;
            }
        }

        .forget-password-link {
            color: #333;
            font-size: 0.8rem;
            text-decoration: none;
            color: #333;
            font-size: 0.8rem;
            width: 100%;
            text-align: center;
            margin-top: 30px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .green-message {
        padding: 20px;
        border-radius: 4px;
        background-color: #04bf6c;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        white-space: nowrap;
    }

}

@media screen and (max-width:992px) {
    .tulu-login-page {
    
        .login-body {
            padding: 0 1.5rem;

            .body-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                form {
                    width: 100%;
                }
            }
    
            .title {
                width: 100%;
            }
    
            .tulu-input {
                width: 100%;
            }
    
            .login-btn {
                width: 100%;
            }
        }
    
    }
}
